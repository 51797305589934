.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.hover-card {
    transition: transform 0.2s ease-in-out;
}

.hover-card:hover {
    transform: translateY(-5px);
}

.modal-dialog.modal-lg {
    max-width: 80%;
}

.card {
    background-color: #f8f9fa;
    border: none;
}

.card-body {
    padding: 1.5rem;
}

.card-title {
    color: #2c3e50;
    margin-bottom: 1rem;
}

.btn-primary {
    background-color: #3498db;
    border-color: #3498db;
    transition: all 0.2s ease-in-out;
}

.btn-primary:hover {
    background-color: #2980b9;
    border-color: #2980b9;
    transform: scale(1.05);
}

.modal-content {
    border-radius: 10px;
    overflow: hidden;
}

.modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.modal-body {
    background-color: #000;
}

video {
    display: block;
    max-height: 80vh;
}

/* Add these styles for footer and main content layout */
body {
    min-height: 100vh;
    margin: 0;
    padding-bottom: 60px; /* Height of the footer */
    position: relative;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Fixed height of footer */
    background-color: #212529;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-content {
    min-height: calc(100vh - 60px); /* Viewport height minus footer height */
    padding-bottom: 2rem;
}

.video-thumbnail {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background-color: #f8f9fa;
    overflow: hidden;
}

.video-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.thumbnail-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9ecef;
}

.thumbnail-placeholder i {
    font-size: 3rem;
    color: #6c757d;
}

.card:hover .video-thumbnail img {
    transform: scale(1.05);
}

.play-icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.play-icon-overlay:hover {
    transform: translate(-50%, -50%) scale(1.1);
}

.thumbnail-placeholder {
    cursor: pointer;
    background-color: #2c3e50;
    position: relative;
}
