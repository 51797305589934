body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.video-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card {
  transition: transform 0.2s;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
}

.video-thumbnail {
  height: 200px;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* Remove the .card-img-top style as we're not using img tags anymore */

.video-player-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-player {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-card {
  transition: all 0.3s ease;
  height: 100%;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.video-card.selected {
  border: 2px solid #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.video-thumbnail {
  height: 150px;
  object-fit: cover;
}

.date-header {
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

/* Add Bootstrap icons */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

.client-logo {
    max-width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}
